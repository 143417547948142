import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MarkdownIt from 'markdown-it';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useSpring } from 'react-spring';
import Popover from '../../../../components/PopoverContainer/Popover';
import {
  Container,
  ContainerContentWrapper,
  ContainerWrapper,
  Dot,
  DotAccomplished,
  DotSlides,
  ImageContainer,
  ImageSlide,
  ButtonContainer,
  Progress,
  Title,
  Content,
  Tooltip,
} from './styles';
import { ArrowBackIcon } from '../../../../assets/svg/SVGComponents';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import { ResourceType, StepType } from '../../../../utils/enums';
import { useToast } from '../../../../hooks/toast';
import IStepProps from '../../../../dtos/IStepsProps';
import useContainerSize from '../../../../hooks/containerSize';
import SmallPopover from '../../../../components/PopoverContainer/SmallPopover';
import { useFeedback } from '../../../../hooks/feedback';

interface SlidesProps {
  title: string;
  content: string;
  image: string;
}

interface IContentSizeProps {
  computedLineHeight: string;
  font: string;
}

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

type ContentProps = 'small' | 'medium' | 'large';

const SlideComponent: React.FC<IComponentProps> = ({ currentStep: step }) => {
  const { addToast } = useToast();
  const { t } = useTranslation();
  const [slideContent, setSlideContent] = useState<SlidesProps[] | null>(null);
  const [isTallArray, setIsTallArray] = useState<boolean[]>([]);
  const [textWidth, setTextWidth] = useState<ContentProps>('large');
  const contentRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [MD, setMD] = useState('');
  const [dotsPerRow, setDotsPerRow] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [enableButtons, setEnableButtons] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [currentSlide, setCurrentSlide] = useState<SlidesProps | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [backButtonDisable, setBackButtonDisable] = useState(false);
  const [nextButtonDisable, setNextButtonDisable] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const { setOpenFeedback, openFeedback, feedbacks } = useFeedback();
  const [visibleSmallPopover, setVisibleSmallPopover] = useState(false);
  const [visibleLargePopover, setVisibleLargePopover] = useState(false);
  const [openSmallPopover, setOpenSmallPopover] = useState(false);
  const [openLargePopover, setOpenLargePopover] = useState(false);
  const [openPopup, setOpenPopup] = useState(true);

  const loadDescriptionMarkdown = useCallback(
    async (currentStep: IStepProps) => {
      let descriptionURL = '';

      if (currentStep && currentStep.resourse_type === ResourceType.SLIDE) {
        if (i18next.language === 'en') {
          descriptionURL = currentStep.resource_url_en;
        }

        if (i18next.language === 'pt-BR') {
          descriptionURL = currentStep.resource_url;
        }
      }
      if (!descriptionURL) {
        // Não prossiga se a URL não foi definida
        return;
      }
      try {
        const response = await fetch(descriptionURL);
        const text = await response.text();

        setMD(text);
      } catch (error) {
        addToast({
          type: 'error',
          expression: 'Ops!',
          description: t('toasts.errors.load_description_fail'),
        });
      }
    },
    [addToast, t],
  );

  const contentSize = useContainerSize(contentRef);
  useEffect(() => {
    if (step) {
      loadDescriptionMarkdown(step);
    }
  }, [loadDescriptionMarkdown, step]);

  const calculateWidthContent = useCallback(
    (slidesData: SlidesProps[]): ContentProps => {
      let averageCharacter = 0;
      if (slidesData) {
        const totalLength = slidesData.reduce(
          (acc, slide) => acc + slide.content.length,
          0,
        );
        averageCharacter = Math.round(totalLength / slidesData.length);

        if (averageCharacter <= 200) {
          return 'small';
        }
        if (averageCharacter > 200 && averageCharacter < 400) {
          return 'medium';
        }
        if (averageCharacter >= 400) {
          return 'large';
        }
      }
      return 'large';
    },
    [],
  );

  const extractslidesFromMarkdown = useCallback(() => {
    const md = new MarkdownIt();
    let slideParts = [];
    if (step.step_type === StepType.INTRO) slideParts = MD.split('#').slice(1);
    else slideParts = MD.split('##').slice(1);

    const slideArray: SlidesProps[] = slideParts.map((part): SlidesProps => {
      const lines = part.split('\n');
      const titleSlide = lines[0];

      const imageLine = lines.find((line: string) => line.includes('!['));
      let imageURL = '';
      if (imageLine) {
        const regex = /!\[.*?\]\((.*?)\)/;
        const match = regex.exec(imageLine);
        // eslint-disable-next-line prefer-destructuring
        if (match) imageURL = match[1];
      }
      let contentSlide = '';

      if (imageLine) {
        contentSlide = md.render(
          lines.slice(1).join('\n').replace(imageLine, ''),
        );
      }
      const newSlide = {
        title: titleSlide,
        image: imageURL,
        content: contentSlide,
      };

      return newSlide;
    });
    setSlideContent(slideArray);
    setIsTallArray(new Array(slideArray.length).fill(false));
    const calc = calculateWidthContent(slideArray) as ContentProps;

    setTextWidth(calc);
  }, [MD, calculateWidthContent, step]);

  useEffect(() => {
    if (totalSlides >= 16) {
      setDotsPerRow(16);
    } else if (totalSlides === 1) {
      setDotsPerRow(1);
    } else setDotsPerRow(totalSlides + 1);
  }, [totalSlides]);

  const handleDotClick = (index: number): void => {
    setCurrentIndex(index);
  };

  const handleMouseEnter = (index: number): void => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = (): void => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    if (contentRef.current) {
      const links = contentRef.current.getElementsByTagName('a');
      for (let x = 0; x < links.length; x += 1) {
        const link = links[x];

        const href = link.getAttribute('href');

        // eslint-disable-next-line no-continue
        if (!href) continue;

        const templink = document.createElement('a');

        templink.href = href;

        const internalLink = templink.hostname === window.location.hostname;

        if (internalLink) {
          link.removeAttribute('target');
          link.removeAttribute('rel');
        }

        if (!internalLink) {
          links[x].setAttribute('target', '_blank');
          links[x].setAttribute('rel', 'noopener noreferrer');
        }

        if (
          links[x].textContent === 'Baixar' ||
          links[x].textContent === 'Baixar aqui!' ||
          links[x].textContent === 'Baixar Molde'
        ) {
          links[x].setAttribute('download', 'sem_nome.png');
        }
      }
    }
  }, [currentSlide]);

  const handleImageLoad = (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ): void => {
    const image = e.target as HTMLImageElement;
    const imageHeight = image.naturalHeight;
    const imageWidth = image.naturalWidth;
    const containerHeight = imageRef.current?.offsetHeight;
    const containerWidth = imageRef.current?.offsetWidth;

    const newIsTallArray = [...isTallArray];
    // aspect ratio to tamanho medio como padrão
    let containerAspectRatio = 1097.28 / 631.391;

    if (containerWidth && containerHeight)
      containerAspectRatio = containerWidth / containerHeight;
    // Proporção da imagem
    const imageAspectRatio = imageWidth / imageHeight;

    // Se a proporção da imagem for maior que a do contêiner, use 'cover', senão 'contain'
    newIsTallArray[currentIndex] = imageAspectRatio > containerAspectRatio;
    setIsTallArray(newIsTallArray);
  };

  useEffect(() => {
    if (MD) {
      setCurrentIndex(0);
      extractslidesFromMarkdown();
    }
  }, [MD, extractslidesFromMarkdown]);

  const handleSlide = useCallback(() => {
    if (slideContent) {
      setCurrentSlide(slideContent[currentIndex]);
      setTotalSlides(slideContent.length);
    }
  }, [currentIndex, slideContent]);

  useEffect(() => {
    handleSlide();
  }, [handleSlide]);

  useEffect(() => {
    if (currentIndex) {
      if (currentIndex <= 0) {
        setBackButtonDisable(true);
      } else {
        setBackButtonDisable(false);
      }

      if (currentIndex >= totalSlides) {
        setNextButtonDisable(true);
      } else {
        setNextButtonDisable(false);
      }
    }
  }, [currentIndex, totalSlides]);

  const moveToNextSlide = useCallback(() => {
    setCurrentIndex(currentIndex === totalSlides - 1 ? 0 : currentIndex + 1);
  }, [currentIndex, totalSlides]);

  const moveToPrevSlide = useCallback(() => {
    setCurrentIndex(currentIndex === 0 ? totalSlides - 1 : currentIndex - 1);
  }, [currentIndex, totalSlides]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent): void => {
      if (e.key === 'ArrowRight') {
        moveToNextSlide();
      }
      if (e.key === 'ArrowLeft') {
        moveToPrevSlide();
      }
    },
    [moveToNextSlide, moveToPrevSlide],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    // Limpa o ouvinte quando o componente for desmontado
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // ajusta font size de acordo com o tamanho do conteúdo
  const fontSize = useMemo(() => {
    if (!currentSlide || !contentSize.width) return '1.5rem';

    const baseFontSize = 16; // Base em pixels
    const scaleFactor = contentSize.width / 600; // Exemplo de fator de escala com base na largura do contêiner

    return `${baseFontSize * scaleFactor}px`;
  }, [contentSize.width, currentSlide]);
  // ajusta line height de acordo com o tamanho do conteúdo
  const lineHeight = useMemo(() => {
    if (!currentSlide) return '1.2rem';

    const baseLineHeight = 1.5;
    const contentLength = currentSlide.content.length;

    if (contentLength <= 200) {
      return `${baseLineHeight * 1}rem`;
    }
    if (contentLength <= 400) {
      return `${baseLineHeight * 1}rem`;
    }
    return `${baseLineHeight * 0.8}rem`;
  }, [currentSlide]);
  // ajusta o tamanho do texto de acordo com o tamanho do conteúdo
  const textFit = useCallback((pixelsPerLine: number) => {
    let textFitValue: IContentSizeProps = {
      computedLineHeight: '1.5rem',
      font: '1.2rem',
    };

    if (contentRef.current) {
      if (pixelsPerLine >= 18 && pixelsPerLine <= 22) {
        textFitValue = {
          computedLineHeight: '1.2rem',
          font: '1.1rem',
        };
      }

      if (pixelsPerLine >= 23 && pixelsPerLine <= 27) {
        textFitValue = {
          computedLineHeight: '1.2rem',
          font: '1rem',
        };
      }

      if (pixelsPerLine >= 28) {
        textFitValue = {
          computedLineHeight: '1rem',
          font: '.8rem',
        };
      }
    }

    return textFitValue;
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const computedStyle = window.getComputedStyle(contentRef.current);
      const lineHeightSize = parseFloat(computedStyle.lineHeight);
      const defaultLineHeight = 1.5;
      const contentHeight = contentRef.current.offsetHeight;

      const pixelsPerLine = Math.round(contentHeight / defaultLineHeight);

      const { computedLineHeight, font } = textFit(pixelsPerLine);
    }
  }, [contentSize.height, currentSlide, lineHeight, textFit]);

  const handleMouseMove = (event: React.MouseEvent): void => {
    if (!contentRef.current) return;

    const contentHeight = contentRef.current.offsetWidth;
    const buttonRect = event.currentTarget.getBoundingClientRect();

    setMousePosition({
      x: event.clientX - buttonRect.x,
      y: event.clientY - 1, // Ajusta para aparecer acima do mouse
    });
  };

  useEffect(() => {
    if (totalSlides) {
      if (totalSlides > 1) {
        setEnableButtons(true);
      }
      if (totalSlides <= 1) {
        setEnableButtons(false);
      }
    }
  }, [totalSlides]);

  // configuração do react spring para animação
  const feedbackSmallPopUp = useSpring({
    transform: visibleSmallPopover ? 'scale(1)' : 'scale(0)',
    config: { tension: 500 },
  });

  const feedbackLargePopUp = useSpring({
    transform: visibleLargePopover ? 'scale(1)' : 'scale(0)',
    config: { tension: 500 },
  });

  // funções para abrir e fechar o pop up
  const handleCloseSmallPopover = useCallback((): void => {
    setVisibleSmallPopover(false);
    setOpenSmallPopover(false);
    setOpenFeedback(false);
  }, [setOpenFeedback]);

  const handleOpenSmallPopover = useCallback((): void => {
    setVisibleSmallPopover(true);
    setOpenSmallPopover(true);
    setOpenFeedback(true);
  }, [setOpenFeedback]);

  const handleCloseLargerPopover = useCallback((): void => {
    if (openFeedback) {
      setVisibleLargePopover(false);
      setOpenLargePopover(false);
      setOpenFeedback(false);
    }
  }, [openFeedback, setOpenFeedback]);

  const handleOpenLargerPopover = useCallback((): void => {
    setVisibleLargePopover(true);
    setOpenLargePopover(true);
    setOpenFeedback(true);
  }, [setOpenFeedback]);

  // configuração do temporizador
  useEffect(() => {
    const duration = 120000;
    if (openPopup) {
      if (!openFeedback) {
        const timer = setTimeout(() => {
          handleOpenSmallPopover();
        }, duration);

        return () => {
          clearTimeout(timer);
        };
      }
    }
    return undefined;
  }, [
    openSmallPopover,
    handleOpenSmallPopover,
    visibleSmallPopover,
    handleCloseSmallPopover,
    openFeedback,
    openPopup,
  ]);

  // função para se caso é necessário abrir o popup
  useEffect(() => {
    if (feedbacks.rating && feedbacks.comment) {
      setOpenPopup(false);
    }
  }, [feedbacks.comment, feedbacks.rating]);

  return (
    <Container>
      {currentSlide && (
        <ContainerWrapper>
          {openLargePopover && (
            <Popover
              closePopover={handleCloseLargerPopover}
              openPopover={openLargePopover}
              positiontop={0}
              positionleft={34}
              style={feedbackLargePopUp}
              setTimer={false}
            />
          )}
          <ContainerContentWrapper width={textWidth}>
            <div>
              <Title>{currentSlide.title}</Title>

              <Content
                className="markdown-container"
                length={currentSlide.content.length}
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: currentSlide.content }}
              />
            </div>
            <Progress width={textWidth}>
              <button
                type="button"
                disabled={backButtonDisable}
                onClick={() => moveToPrevSlide()}
              >
                <ArrowBackIcon />
              </button>
              {slideContent && currentIndex >= 0 && (
                <DotSlides dots={dotsPerRow}>
                  {slideContent.map((slide, index) => (
                    <Dot
                      key={index}
                      width={textWidth}
                      onClick={() => handleDotClick(index)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave()}
                      onMouseMove={handleMouseMove}
                    >
                      <DotAccomplished
                        accomplished={index + 1 <= currentIndex + 1}
                        width={textWidth}
                      />

                      {hoveredIndex === index && (
                        <Tooltip x={mousePosition.x} y={mousePosition.y}>
                          {slide.title}
                        </Tooltip>
                      )}
                    </Dot>
                  ))}
                </DotSlides>
              )}
              <button
                type="button"
                disabled={nextButtonDisable}
                onClick={() => moveToNextSlide()}
              >
                <ArrowBackIcon transform="scale(-1, 1)" />
              </button>
            </Progress>
          </ContainerContentWrapper>

          <ImageContainer ref={imageContainerRef}>
            <SmallPopover
              closePopover={handleCloseSmallPopover}
              openPopover={openSmallPopover}
              openLargePopover={handleOpenLargerPopover}
              positiontop={0}
              positionleft={30}
              style={feedbackSmallPopUp}
            />
            <ImageSlide
              onLoad={handleImageLoad}
              isTall={isTallArray[currentIndex]}
              key={currentSlide.title}
              src={currentSlide.image}
              alt="content"
            />
            {enableButtons && (
              <ButtonContainer
                onMouseEnter={() => setShowButtons(true)}
                onMouseLeave={() => setShowButtons(false)}
                hover={showButtons}
              >
                <button
                  type="button"
                  disabled={backButtonDisable}
                  onClick={() => moveToPrevSlide()}
                >
                  <ArrowBackIcon
                    width="1.22rem"
                    stroke="#fff"
                    strokeWidth={4}
                    fill="none"
                  />
                </button>

                <button
                  type="button"
                  disabled={nextButtonDisable}
                  onClick={() => moveToNextSlide()}
                >
                  <ArrowBackIcon
                    width="1.22rem"
                    stroke="#fff"
                    strokeWidth={4}
                    fill="none"
                    transform="scale(-1, 1)"
                  />
                </button>
              </ButtonContainer>
            )}
          </ImageContainer>
        </ContainerWrapper>
      )}
    </Container>
  );
};

export default SlideComponent;
